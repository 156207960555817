import { useRouter } from 'next/navigation'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { BasicButton } from '@/components/uis/Button/BasicButton'
import { fontWeight, fontSize } from '@/utils/themeConfigs/customTheme'

const Container = styled(Box)`
  position: relative;
  height: 100vh;
`

const Content = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

const Title = styled(Typography)`
  font-size: ${fontSize.xxxl};
  font-weight: ${fontWeight.bold};
  margin-bottom: 8px;
`
const Text = styled(Typography)`
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.normal};
  margin-bottom: 32px;
`

const Custom404 = () => {
  const router = useRouter()

  const handleClick = () => {
    void router.push('/')
  }

  return (
    <Container>
      <Content>
        <Title variant='h2'>404 not found</Title>
        <Text variant='body2'>ページを表示できませんでした。</Text>
        <BasicButton
          width='240px'
          label='CHATに戻る'
          variant='outlined'
          onClick={handleClick}
        />
      </Content>
    </Container>
  )
}

export default Custom404
